$clr-global-app-background: #ffffff;

$clr-content-area-padding-top: O;
$clr-content-area-padding-right: 0;
$clr-content-area-padding-bottom: 0;
$clr-content-area-padding-left: O;

$clr-header-font-color: #0079b8;
$clr-header-title-font-size: 1rem;

$component-border-radius: 8px;
$container-border-radius: 16px;
$component-border-width: 2px;

$vic-elevation-4:
  0px 0.3px 0.9px rgba(0, 0, 0, 0.108),
  0px 1.6px 3.6px rgba(0, 0, 0, 0.132);
$vic-elevation-8:
  0px 0.6px 1.8px rgba(0, 0, 0, 0.108),
  0px 3.2px 7.2px rgba(0, 0, 0, 0.132);
$vic-elevation-16:
  0px 1.2px 3.6px rgba(0, 0, 0, 0.108),
  0px 6.4px 14.4px rgba(0, 0, 0, 0.132);
$vic-elevation-64:
  0px 4.8px 14.4px rgba(0, 0, 0, 0.18),
  0px 25.6px 57.6px rgba(0, 0, 0, 0.22);

$clr-baseline-px: 20px !default;
