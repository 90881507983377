@import './colors';

$large-border-radius: 5px;
$default-border-radius: 5px;
$small-border-radius: 4px;

$default-border-width: 2px;
$small-border-width: 1px;

$page-padding: 16px 24px 48px 24px;
$card-padding: 16px;
$small-card-padding: 16px;

$margin: 16px;
$margin-small: 8px;

$elevation-4:
  0px 0.3px 0.9px rgba(0, 0, 0, 0.108),
  0px 1.6px 3.6px rgba(0, 0, 0, 0.132);
$elevation-8:
  0px 0.6px 1.8px rgba(0, 0, 0, 0.108),
  0px 3.2px 7.2px rgba(0, 0, 0, 0.132);
$elevation-16:
  0px 1.2px 3.6px rgba(0, 0, 0, 0.108),
  0px 6.4px 14.4px rgba(0, 0, 0, 0.132);
$elevation-64:
  0px 4.8px 14.4px rgba(0, 0, 0, 0.18),
  0px 25.6px 57.6px rgba(0, 0, 0, 0.22);

.val-card {
  box-shadow: $elevation-4;
  border-radius: $large-border-radius;
  padding: $card-padding;
  background: $white;
  margin-bottom: 24px;
}

.val-card-64 {
  @extend .val-card;

  margin-top: 8px;
  padding: 8px 16px !important;
  padding: $small-card-padding;
  box-shadow: $elevation-16;
  margin-bottom: 0px;
}

.val-small-card {
  @extend .val-card;
  padding: $small-card-padding;
  box-shadow: $elevation-16;
}

.card-container {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-row {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  padding-bottom: 24px;
  overflow-x: hidden;
}

.auto-card {
  display: flex;
  width: 100%;
  flex: 1 0;
  flex-grow: 1;
  overflow-x: auto;
  margin-bottom: 0px;
  white-space: nowrap;
}

.val-datagrid {
  max-width: -webkit-fill-available;
  height: 100% !important;
  width: -webkit-fill-available;
}

.clr-row {
  overflow-x: hidden !important;
  width: -webkit-fill-available;
  margin-bottom: 4vh;
}

clr-tabs ul.nav {
  height: unset;
}

clr-tabs ul li button.active {
  color: #172983 !important;
}

clr-tabs ul li button {
  color: #b2b2b2;
}

clr-tabs ul li button clr-icon {
  color: #b2b2b2 !important;
}

clr-tabs ul li button.active clr-icon {
  color: #172983 !important;
}
