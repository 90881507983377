.datagrid {
  border: 0;
  margin-top: 0;
  background: #fff;
}

.datagrid-header {
  border: 0;
  border-radius: 16px;
  background: #fff;
}

.datagrid-footer {
  border: 0;
  border-radius: 16px;
  background: #fff;
}

.datagrid-host {
  // box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  background: #fff;
  border-radius: 10px;
  border: 0;
  // background-clip: border-box;
  transition:
    transform 0.3s cubic-bezier(0.34, 2, 0.6, 1),
    box-shadow 0.2s ease,
    -webkit-transform 0.3s cubic-bezier(0.34, 2, 0.6, 1);
  //box-shadow: rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px, rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px;
}

.datagrid-table {
  outline-color: #fff;
}
