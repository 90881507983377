.clr-error .clr-select-wrapper::after, .clr-success .clr-select-wrapper::after {
  right: 0.3rem;
}

.clr-control-container {
  .clr-select-wrapper {
    width: 100%;
  }

  select, .clr-input {
    border: 0.05rem solid #B3B3B3;
    border-radius: 5px;
    width: 100%;
  }

  select:disabled {
    color: #B3B3B3;
    color: var(--clr-forms-text-disabled-color, hsl(198, 0%, 70%));
  }

  &.clr-error {
    select:not([multiple]), .clr-input {
      border-color: var(--clr-forms-invalid-color, hsl(9, 100%, 38%));
    }

    .clr-select-wrapper select:not([multiple]), .clr-multiselect-wrapper select:not([multiple]) {
      &:focus {
        background: linear-gradient(to bottom,transparent 95%,var(--clr-forms-invalid-color, hsl(9, 100%, 38%)) 95%) no-repeat;
      }
    }

    cds-icon.clr-validate-icon {
      display: none;
    }
  }
}
