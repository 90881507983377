@import './colors';
@import './layout';
@import './fonts';

$vic-button-border: none;
$vic-button-font-size: 1rem;

.vic-button {
  display: inline-block;

  padding: 8px 0px;
  margin-top: 1rem !important;
  height: auto;

  color: white;
  background: $main-gradient;
  border-radius: $component-border-radius;
  border: $vic-button-border;

  font-size: $vic-button-font-size;
  @extend %font-primary;
  text-transform: none;
  letter-spacing: inherit;

  &:hover {
    color: white !important;
    filter: brightness(105%);
  }

  &:focus {
    outline: none !important;
  }

  &:active {
    box-shadow: none !important;
    filter: brightness(95%);
  }
}

.vic-primary-button {
  background: $primary-color !important;

  @extend .vic-button;
}
