@import './images';
@import './colors';
@import './fonts';

.nav-group-trigger {
  background-color: transparent;
  @extend %font-primary;
}

.nav-text,
.nav-group-text {
  color: $gray;
  @extend %font-primary;

  &:hover {
    color: $dark-gray;
    background-color: $light-gray;
  }
}

.nav-link,
.nav-group-trigger {
  &:hover {
    color: $dark-gray;
    background-color: $light-gray !important;
  }
}

.nav-link,
.nav-group-trigger,
.nav-group {
  &.active {
    color: $dark-gray;
    border: 0px !important;
  }
}

.clr-vertical-nav {
  height: 100%;
  // border-right: 1px solid #ccc;
  background: linear-gradient(180deg, #f1f3fa 0%, #fcfcfc 100%);
  box-shadow: $vic-elevation-16;

  .uncollapsed-link {
    text-align: center;
    transform: translate(-0%, -0%);
    padding-bottom: 20px;
    img {
      height: 100px;
    }
  }

  .collapsed-link {
    text-align: center;
    transform: translate(0%, 0%);
    // border-bottom: 1px solid #ccc !important;
    padding-bottom: 10px;
    padding-top: 0px;
    img {
      width: 24px;
      height: 24px;
    }
  }
}

.clr-vertical-nav.is-collapsed {
  .nav-content::-webkit-scrollbar {
    width: 0px !important;
  }
}

.clr-vertical-nav .nav-trigger--bottom .nav-trigger {
  display: none !important;
}

.clr-vertical-nav .nav-link.active {
  border-top: 1px solid #ccc !important;
  border-bottom: 1px solid #ccc !important;
}

.clr-vertical-nav .nav-group.active {
  border-top: 1px solid #ccc !important;
  border-bottom: 1px solid #ccc !important;
}

a {
  cursor: pointer;
}
