@import './layout';
@import './fonts';
@import './colors';

%button-base {
  border-radius: $default-border-radius;
  margin: 0px;
  text-align: center;
  @extend .button-label;

  cursor: pointer;
}

.button-primary {
  @extend %button-base;
  margin-left: 4px;

  background-color: $secondary-color-xd;
  border: none !important;
  box-shadow: $elevation-8;
  color: $white;

  &:hover {
    background-color: $secondary-color-xd;
    box-shadow: $elevation-16;
    color: $white;
    filter: brightness(105%);
  }
}

.button-secondary {
  @extend %button-base;

  background-color: transparent;
  color: $secondary-color-xd;
  border: $small-border-width solid $light-gray;
  box-shadow: $elevation-16;
  margin-right: 4px;

  &:hover {
    background-color: $secondary-color-xd;
    box-shadow: $elevation-16;
    color: $white;
    filter: brightness(105%);
  }
}

.button-tertiary {
  @extend %button-base;

  background-color: transparent;
  color: $secondary-color-xd;
  border: none;
  margin-right: 4px;

  &:hover {
    background-color: $secondary-color-xd;
    box-shadow: $elevation-16;
    color: $white;
    filter: brightness(105%);
  }
}

.icon-button {
  cursor: pointer;

  &:hover {
    filter: brightness(110%);
  }

  &:active {
    filter: brightness(95%);
  }
}
