$primary-color: #59cbe4;
$secondary-color: #8177eb;

$black: black;
$dark-gray: #303030;
$silver: #cccccc;
$gray: #777777;
$medium-grey: #666666;
$light-gray: #e0e0e0;
$off-white: #ebebeb;
$white: white;

$utility-main: #59e480;
$utility-secondary: #e45e5e;

$background: #f4f4f4;
$header: #f8faff;

$primary-color-xd: #172983;
$primary-color-xd-with-opacity: #172983bf;

$secondary-color-xd: #009ede;

$main-gradient: linear-gradient(
  90deg,
  $primary-color 0%,
  $secondary-color 100%
);
$button-gradient: linear-gradient(
  102deg,
  $secondary-color-xd 0%,
  $primary-color-xd 100%
);
