@import './colors';

@mixin font($font-family, $font-file, $font-weight: 400) {
  @font-face {
    font-family: $font-family;
    src:
      url($font-file + '.otf') format('opentype'),
      url($font-file + '.ttf') format('truetype'),
      url($font-file + '.eot'),
      url($font-file + '.eot?#iefix') format('embedded-opentype'),
      url($font-file + '.woff') format('woff'),
      url($font-file + '.svg#aller') format('svg');
    font-weight: $font-weight;
    font-style: normal;
  }
}

@include font('Gilroy', '/assets/fonts/gilroy-extrabold', 800);
@include font('Roboto-Light', '/assets/fonts/Roboto-Light', 300);
@include font('Roboto-Regular', '/assets/fonts/Roboto-Regular', 400);
@include font('Roboto-Medium', '/assets/fonts/Roboto-Medium', 500);
@include font('Roboto-Bold', '/assets/fonts/Roboto-Bold', 700);

$clr-roboto-light: 'Roboto-Light' !important;
$clr-roboto-regular: 'Roboto-Regular' !important;
$clr-roboto-medium: 'Roboto-Medium' !important;
$clr-roboto-bold: 'Roboto-Bold' !important;

$font-size-nano: 8px; // 10
$font-size-tiny: 12px; // 16
$font-size-small: 14px; // 18
$font-size-medium: 16px; // 20
$font-size-big: 20px; // 24
$font-size-large: 24px; //28
$font-size-huge: 28px; //32

$clr-roboto: 'Roboto' !important;
$clr-altFont: 'Gilroy' !important;

.label-default {
  font-family: $clr-roboto-medium;
  font-style: normal;
  font-weight: normal;
  font-size: $font-size-medium;
  text-align: justify;
  color: $gray;
}

.label-nano {
  @extend .label-default;
  font-size: $font-size-nano;
}

.label-tiny {
  @extend .label-default;
  font-size: $font-size-tiny;
}

.label-small {
  @extend .label-default;
  font-size: $font-size-small;
}

.label-medium {
  @extend .label-default;
  font-size: $font-size-medium;
}

.label-big {
  @extend .label-default;
  font-size: $font-size-big;
}

.label-large {
  @extend .label-default;
  font-size: $font-size-large;
}

.label-huge {
  @extend .label-default;
  font-size: $font-size-huge;
}

.button-label {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 800;
  font-size: $font-size-tiny;
  text-align: justify;
  text-transform: uppercase;
  letter-spacing: inherit;
  color: $white;
}

%breadcrumbs-text {
  font-family: $clr-roboto-medium;
  font-style: normal;
  font-weight: 800;
  font-size: $font-size-huge;
  text-align: justify;
  // line-height: 39px;
  text-transform: capitalize;
}

%card-title {
  font-family: $clr-roboto-medium;
  font-style: normal;
  font-weight: 800;
  font-size: $font-size-large;
  // line-height: 34px;
  text-align: justify;
}

%breadcrumbs-tip {
  font-family: $clr-roboto-medium;
  font-style: normal;
  font-weight: 800;
  font-size: $font-size-medium;
  text-align: justify;
  // line-height: 24px;
  text-transform: uppercase;
}

.session-title {
  color: $primary-color-xd !important;
  font-family: $clr-roboto-medium;
  font-size: $font-size-big;
  font-style: normal;
  font-weight: 800;
  // line-height: 39px;
  text-transform: capitalize;
}
