@import './colors';

// ------ Fonts ------

@mixin font($font-family, $font-file, $font-weight: 400) {
  @font-face {
    font-family: $font-family;
    src:
      url($font-file + '.otf') format('opentype'),
      url($font-file + '.ttf') format('truetype'),
      url($font-file + '.eot'),
      url($font-file + '.eot?#iefix') format('embedded-opentype'),
      url($font-file + '.woff') format('woff'),
      url($font-file + '.svg#aller') format('svg');
    font-weight: $font-weight;
    font-style: normal;
  }
}

//@include font('Montserrat', '/assets/fonts/Montserrat-Regular', normal);
//@include font('Montserrat', '/assets/fonts/Montserrat-Bold', 700);
@include font('Gilroy', '/assets/fonts/gilroy-extrabold', 700);
@include font('Roboto-Light', '/assets/fonts/Roboto-Light', 300);
@include font('Roboto-Regular', '/assets/fonts/Roboto-Regular', 400);
@include font('Roboto-Medium', '/assets/fonts/Roboto-Medium', 500);
@include font('Roboto-Bold', '/assets/fonts/Roboto-Bold', 700);

$clr-roboto-light: 'Roboto-Light' !important;
$clr-roboto-regular: 'Roboto-Regular' !important;
$clr-roboto-medium: 'Roboto-Medium' !important;
$clr-roboto-bold: 'Roboto-Bold' !important;

$clr-roboto: 'Roboto' !important;
$clr-altFont: 'Gilroy' !important;

// ------ Variables ------

$vic-label-size: 18px;
$vic-link-size: 14px;

// ------ Components ------

%font-primary {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
}

%font-secondary {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: normal;
}

.vic-label {
  color: $gray;
  font-size: $vic-label-size;
  @extend %font-secondary;
}

.vic-h3 {
  color: $gray;
  font-size: 24px;
  @extend %font-secondary;
}

.vic-link {
  padding-top: 8px;

  //display: flex;
  // color: $light-gray;

  // @extend %font-secondary;
  font-size: $vic-link-size;
  font-weight: bold;

  &:hover {
    // color: $primary-color;
    text-decoration: none;
    cursor: pointer;
  }
}
