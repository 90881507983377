// Toast CSS -----------------------------------------------------------------------------------

.toast {
  width: 600px !important;
}

.toast-success {
  background-color: #76d572 !important;
}

.toast-error {
  background-color: #ec4d3d !important;
}

.toast-warning {
  background-color: #f8cd46 !important;
}

.toast-info {
  background-color: #76c6f5 !important;
}

.toast-user-message {
  width: 600px !important;
  padding: 20px !important;
  background-color: white !important;
  color: black !important;
  border-radius: 10px !important;
  box-shadow: 0px 0px 7px #999 !important;
  transition: box-shadow 0.3s;
  font-size: 13px;

  &:hover {
    box-shadow: 0px 0px 12px #555 !important;
  }

  .toast-close-button {
    color: #000;
    margin-top: -5px;
    transition: all 0.3s;
  }

  p {
    font-size: 13px;
    margin: 0px;
  }
  h1 {
    font-size: 26px;
  }
  h2 {
    font-size: 22px;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 18px;
  }
  h5 {
    font-size: 16px;
  }
  h6 {
    font-size: 14px;
  }
}
