@import './colors';
@import './layout';
@import './fonts';

// ------ Variables ------

$vic-input-background: transparent;
$vic-input-border: solid;
$vic-input-field-padding: 24px 24px;
$vic-component-margin-top: 16px;
$vic-component-font-size: 18px;
$vic-checkbox-border-radius: 4px;

// ------ Components ------

%base-form-component {
  margin-top: $vic-component-margin-top;

  color: $gray;

  @extend %vic-component-outline;

  font-size: $vic-component-font-size;
}

%vic-component-outline {
  &:focus {
    outline: 0;
    box-shadow: 0 0 0 1px rgba($primary-color, 0.25);
  }
}

.vic-text-input {
  @extend %base-form-component;

  padding: $vic-input-field-padding;

  background: $vic-input-background;
  border: $vic-input-border;
  border-color: $light-gray;
  border-width: $component-border-width;
  border-radius: $component-border-radius;

  @extend %font-primary;

  &::placeholder {
    color: $light-gray;
  }

  &:focus {
    color: $dark-gray;
    border-color: $primary-color;
  }
}

.vic-checkbox {
  @extend %base-form-component;
  float: left;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  color: white;
  background-color: $primary-color;
  border-radius: $vic-checkbox-border-radius;
}

// ------ Forms ------

.vic-login-form {
  padding: 32px;
}

.vic-form-group .auth-source,
.vic-form-group .username,
.vic-form-group .password,
.vic-form-group .checkbox,
.vic-form-group .clr-form-control,
.vic-form-group .clr-checkbox-wrapper {
  margin: 0.25rem 0 0.75rem 0;
}
.vic-form-group .clr-control-container {
  display: block;
  width: 100%;
}
.vic-form-group .clr-control-container .clr-select-wrapper,
.vic-form-group .clr-control-container .clr-select {
  width: 100%;
}
.vic-form-group .clr-control-container .clr-input-wrapper > .clr-input {
  width: 100%;
}
.vic-form-group .clr-control-container .clr-input-wrapper {
  width: 100%;
}
.vic-form-group .clr-control-container .clr-textarea-wrapper {
  width: 100%;
}
.vic-form-group .clr-control-container .clr-textarea-wrapper > .clr-textarea {
  width: 100%;
}
.vic-form-group .clr-form-control .clr-control-label {
  text-align: left;
}
.vic-form-group .clr-control-container .clr-input-wrapper > .clr-input-group {
  max-width: 100%;
  width: 100%;
  padding-right: 0.4rem;
}
.vic-form-group
  .clr-control-container
  .clr-input-wrapper
  > .clr-input-group
  > .clr-input {
  width: calc(100% - 1rem);
}
.vic-form-group .tooltip-validation {
  margin-top: 0.25rem;
}
.vic-form-group .tooltip-validation .username,
.vic-form-group .tooltip-validation .password {
  width: 100%;
  margin-top: 0;
}
.vic-form-group .error {
  display: none;
  margin: 0.25rem 0 0 0;
  padding: 0.375rem 0.5rem;
  background: #c92100;
  color: #fafafa;
  border-radius: 0.125rem;
  line-height: 0.75rem;
}
.vic-form-group .error:before {
  display: inline-block;
  content: '';
  background: url('data:image/svg+xml;charset=utf8,%3Csvg%20version%3D%221.1%22%20viewBox%3D%225%205%2026%2026%22%20preserveAspectRatio%3D%22xMidYMid%20meet%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%3Cdefs%3E%3Cstyle%3E.clr-i-outline%7Bfill%3A%23fafafa%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Ctitle%3Eexclamation-circle-line%3C%2Ftitle%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20class%3D%22clr-i-outline%20clr-i-outline-path-1%22%20d%3D%22M18%2C6A12%2C12%2C0%2C1%2C0%2C30%2C18%2C12%2C12%2C0%2C0%2C0%2C18%2C6Zm0%2C22A10%2C10%2C0%2C1%2C1%2C28%2C18%2C10%2C10%2C0%2C0%2C1%2C18%2C28Z%22%3E%3C%2Fpath%3E%3Cpath%20class%3D%22clr-i-outline%20clr-i-outline-path-2%22%20d%3D%22M18%2C20.07a1.3%2C1.3%2C0%2C0%2C1-1.3-1.3v-6a1.3%2C1.3%2C0%2C1%2C1%2C2.6%2C0v6A1.3%2C1.3%2C0%2C0%2C1%2C18%2C20.07Z%22%3E%3C%2Fpath%3E%3Ccircle%20class%3D%22clr-i-outline%20clr-i-outline-path-3%22%20cx%3D%2217.95%22%20cy%3D%2223.02%22%20r%3D%221.5%22%3E%3C%2Fcircle%3E%0A%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fsvg%3E');
  margin: 0.04166667rem 0.25rem 0 0;
  height: 0.666667rem;
  width: 0.666667rem;
}
.vic-form-group .error.active {
  display: -ms-flexbox;
  display: flex;
}
.vic-form-group .error.active:before {
  -ms-flex: 0 0 0.666667rem;
  flex: 0 0 0.666667rem;
}
.vic-form-group .btn {
  margin: 1rem 0 0 0;
  max-width: none;
}
.vic-form-group .error + .btn {
  margin: 1rem 0 0 0;
}
.vic-form-group .submit {
  margin-top: 0.5rem;
  font-size: 0.5833rem;
  text-align: center;
}
