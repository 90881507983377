.container-center {
  position: fixed;
  width: 520px;
  height: 50px;
  text-align: center;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -50%);
}

.content-area {
  overflow: hidden !important;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  padding: 0 !important;
}

.page-body-container {
  min-width: 480px;
  padding: 0rem 1rem !important;
  margin-left: 0 !important;
  // overflow: auto;
  // height: 100% !important;
  overflow: unset;
  // max-height: calc(100vh - 56px) !important;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 56px) !important;
  height: inherit !important;
}
