@import './colors';

.header {
  //box-shadow: $vic-elevation-16;
}

header.header-vic,
.header.header-vic {
  background-color: #fff;

  color: $primary-color-xd !important;
  height: 56px !important;
  margin-left: 0.5rem !important;
}

header.header-actions,
.header.header-actions {
  color: $primary-color-xd !important;
}

.header .branding {
  min-width: 165px;
  padding: 0 !important;
  align-items: center;

  img {
    flex-grow: 0;
    flex-shrink: 0;
    height: 30px;
    width: 165px;
    margin-right: 0.4rem;
    margin-top: 0.4rem;
  }
}

.header .search,
.header .header-nav .nav-link,
.header .header-actions .nav-link {
  color: $primary-color-xd !important;
  padding-right: 10px;
  padding-left: 10px;
}

.header .header-actions .nav-flag-link {
  margin-top: 10px;
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 50%;
  opacity: 0.3;
}

.header .header-actions .nav-flag-link.active,
.header .header-actions .nav-flag-link:hover {
  opacity: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.header .header-actions .nav-link.nav-icon-text clr-icon {
  margin-left: 5px;
  margin-right: 5px;
}

.header .header-actions .container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  margin-left: 1rem;
  .dropdown-toggle {
    padding-top: 0px;
  }
}

.header .header-actions .container.left {
  justify-content: flex-start;
}

.header .search,
.header .search-box {
  max-width: 15rem;
  width: 100%;

  input {
    width: 100%;
    margin: 10px 0rem 10px;
    padding: 5px 15px !important;
    border: none !important;
    box-shadow: 0 0 1px #000;
    border-radius: 100vw;

    color: #000 !important;
    outline-color: #fff;
  }
}
