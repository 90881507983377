@import '../node_modules/ag-grid-community/styles/ag-grid.css';
@import '../node_modules/ag-grid-community/styles/ag-theme-alpine.css';
@import '../node_modules/ag-grid-community/styles/ag-theme-material.css';

@import './app/core/variables';
@import './app/core/core';
@import '~@circlon/angular-tree-component/css/angular-tree-component.css';

@import './scss/core';
@import './scss/forms';

.title-container {
  font-family: $clr-roboto-medium;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-left: -$card-padding !important;
  border-left: 6px solid $primary-color-xd;
  padding-left: $card-padding;
}

.body-db-height {
  height: 100%;
}

.html-db-height {
  height: 100%;
}

.custom-header-pinned {
  background-color: #bec5ad;
  --ag-header-cell-hover-background-color: #bec5ad;

  font-weight: bold;
}

.custom-header-1 {
  background-color: #f2f2f2;
  --ag-header-cell-hover-background-color: #f2f2f2;

  font-weight: bold;
}

.custom-header-2 {
  background-color: #96bbbb;
  --ag-header-cell-hover-background-color: #96bbbb;

  font-weight: bold;
}

.custom-header-3 {
  background-color: #fec601;
  --ag-header-cell-hover-background-color: #fec601;

  font-weight: bold;
}

.custom-header-4 {
  background-color: #bf4040;
  --ag-header-cell-hover-background-color: #bf4040;

  font-weight: bold;
}

.custom-header-5 {
  background-color: #71bf40;
  --ag-header-cell-hover-background-color: #71bf40;

  font-weight: bold;
}

.ag-header {
  font-weight: bold;
}
.ag-tool-panel-wrapper {
  --ag-borders: solid 1px;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: #babfc7;
}
