@import './colors';
@import './fonts';
@import './layout';
@import './buttons';

.toast-top-center {
  bottom: 0%;
  top: 25%;
  margin: 0%;
  right: 0%;
  left: 0%;
}

.page-body-container-fixed {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
}

body {
  p {
    margin-top: 0.75rem;
  }
}

body ._hj_feedback_container ._hj-21t0-__MinimizedWidgetMiddle__label {
  background-color: #33bbec !important;
}

// .svg-color-change {
//     filter: invert(79%) sepia(57%) saturate(812%) hue-rotate(160deg) brightness(93%) contrast(91%);
// }
