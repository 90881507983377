$primary-color: #59cbe4;
$secondary-color: #8177eb;

$text: black;
$dark-gray: #303030;
$gray: #777777;
$light-gray: #e0e0e0;

$utility-main: #a1e5a8;

$success-toast: #94d7a3;
$information-toast: #709cf0;
$warning-toast: #f0c470;
$error-toast: #e48383;

$background: #edf4f5;
$card-background: white;

$primary-color-xd: #172983;
$primary-color-xd-with-opacity: #172983bf;

$secondary-color-xd: #009ede;

// Gradients

$main-gradient: linear-gradient(
  90deg,
  $primary-color 0%,
  $secondary-color 100%
);
$button-gradient: linear-gradient(102deg, #009ede 0%, #1a2981 100%);
